import PropTypes from "prop-types";
import { useState } from "react";

function ExpandableBox({ title, children, className }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={className}>
      <span
        className="text-sm text-[#4fbba9] underline cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {title}
      </span>
      {isExpanded && <div>{children}</div>}
    </div>
  );
}

ExpandableBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ExpandableBox;
