import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import {
  Login,
  DeleteAccountSteps,
  Register,
  ForgotPassword,
  ResetPassword,
  PortalLogin,
  Challenge,
} from "pages";

import { useTheme } from "@givenergy/tailwind-theming";

function App() {
  const [, { set: setTheme }] = useTheme();

  useEffect(() => {
    const setDarkMode = (isDarkMode) => {
      setTheme(isDarkMode ? "givenergy-dark" : "givenergy");
    };

    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // We don't currently allow the user to manually switch
    // between themes, so always check for the system preference
    // on mount to override any previously stored value in
    // localStorage.
    setDarkMode(darkModeQuery.matches);

    const onChange = (event) => {
      setDarkMode(event.matches);
    };

    darkModeQuery.addEventListener("change", onChange);

    return () => {
      darkModeQuery.removeEventListener("change", onChange);
    };
  }, []);

  return (
    <Routes>
      <Route path="/login" element={Login()} />
      <Route path="/challenge" element={Challenge()} />
      <Route path="/portal-login" element={PortalLogin()} />
      <Route path="/forgot-password" element={ForgotPassword()} />
      <Route path="/reset-password" element={ResetPassword()} />
      <Route path="/register" element={Register()} />
      <Route path="/delete-steps" element={DeleteAccountSteps()} />
    </Routes>
  );
}

export default App;
