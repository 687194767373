import PropTypes from "prop-types";

function ErrorBanner({ children }) {
  return (
    <div className="bg-danger-200 dark:bg-danger-400 rounded-md p-2 text-center">
      <span className="text-danger-500 dark:text-danger-50">{children}</span>
    </div>
  );
}

ErrorBanner.propTypes = {
  children: PropTypes.node,
};

export default ErrorBanner;
