import "react-phone-number-input/style.css";
import "./phone-input.css";
import ReactPhoneNumberInput, {
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "hooks/use-translation";

function PhoneInput({ value, setValue, className, label, ...rest }) {
  const { tcomponent, tcommon } = useTranslation({
    component: "phone-input",
  });

  const inputLabel = label || tcommon("phone-number");

  const error = !isPossiblePhoneNumber(value ?? "");

  return (
    <div className={clsx("flex flex-col gap-2", className)}>
      <label
        htmlFor="phone-number"
        className="text-sm text-primary-muted xs:min-w-24 group-focus-within:text-brand-500"
      >
        {inputLabel}
      </label>
      <ReactPhoneNumberInput
        {...rest}
        id="phone-number"
        international
        type="tel"
        name="phone-number"
        countryCallingCodeEditable={false}
        defaultCountry="GB"
        required
        value={value}
        onChange={setValue}
      />
      {error && (
        <span className="text-sm text-danger-500">{tcomponent("error")}</span>
      )}
    </div>
  );
}

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default PhoneInput;
