import { useRef, useState } from "react";
import { Trans } from "react-i18next";

import { usePasswordCompare } from "hooks/use-password-compare";
import { useTranslation } from "hooks/use-translation";

import Input from "components/input";
import Button from "components/button";
import PasswordInput from "components/password-input";
import { usePasswordCriteria } from "hooks/use-password-criteria";
import { MainWrapper } from "components/main-wrapper";

const { REACT_APP_SSO_DOMAIN } = process.env;

export function ResetPassword() {
  const { translate, tpage, tcommon } = useTranslation({
    page: "reset-password",
  });
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [fields, setFields] = useState({
    username: new URLSearchParams(window.location.search).get("email") ?? "",
    password: "",
    confirmedPassword: "",
    nonce: new URLSearchParams(window.location.search).get("nonce") ?? "",
  });

  const [renderPasswordCriteria, validPassword, setPasswordForCriteria] =
    usePasswordCriteria();

  const [error, setError] = useState(false);

  const passwordConfirm = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validPassword == false) return;

    setResetting(true);
    setError(false);

    try {
      const res = await fetch(`${REACT_APP_SSO_DOMAIN}/reset`, {
        method: "POST",
        body: JSON.stringify({
          email: fields.username,
          password: fields.password,
          confirmedPassword: fields.confirmedPassword,
          nonce: fields.nonce,
        }),
      });
      setError(!res.ok);
      setResetSuccess(res.ok);
    } catch {
      setError(true);
    }

    setResetting(false);
  };

  usePasswordCompare({ passwordConfirm }, fields);

  if (resetSuccess) {
    return (
      <MainWrapper>
        <div className="text-sm text-center">
          <p className="mb-10">{tpage("reset-successful")}</p>
          <a
            className="underline"
            href={`/login${
              window.localStorage.getItem("loginSearchParams") ?? ""
            }`}
          >
            {tpage("return-to-login")}
          </a>
        </div>
      </MainWrapper>
    );
  }
  return (
    <MainWrapper>
      <form className="space-y-2" onSubmit={handleSubmit}>
        <Input
          label={tcommon("email")}
          id="email"
          type="email"
          value={fields.username}
          onChange={(event) =>
            setFields({ ...fields, username: event.target.value })
          }
          required
        />

        <PasswordInput
          label={tcommon("password")}
          id="password"
          value={fields.password}
          onChange={(event) => {
            setFields({ ...fields, password: event.target.value });
            setPasswordForCriteria(event.target.value);
          }}
          required
        />

        <PasswordInput
          ref={passwordConfirm}
          label={tcommon("confirm")}
          id="password-confirm"
          name="confirmedPassword"
          value={fields.confirmedPassword}
          onChange={(event) =>
            setFields({ ...fields, confirmedPassword: event.target.value })
          }
          required
        />

        {renderPasswordCriteria()}

        {error && (
          <p className="max-w-full text-sm text-danger-500">
            <Trans t={translate} i18nKey={"page.reset-password.retry-cta"} />
          </p>
        )}

        <div className="flex justify-end">
          <Button type="submit" disabled={resetting}>
            {tpage("save-changes")}
          </Button>
        </div>
      </form>

      <div className="text-sm text-center">
        <a
          className="underline"
          href={`/login${
            window.localStorage.getItem("loginSearchParams") ?? ""
          }`}
        >
          {tpage("return-to-login")}
        </a>
      </div>
    </MainWrapper>
  );
}
