import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { usePasswordCompare } from "hooks/use-password-compare";
import { useTranslation } from "hooks/use-translation";
import PasswordInput from "components/password-input";
import Input from "components/input";
import Button from "components/button";
import { MainWrapper } from "components/main-wrapper";
import { usePasswordCriteria } from "hooks/use-password-criteria";
import PhoneInput from "components/phone-input";
import ExpandableBox from "components/expandable-box";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const { REACT_APP_SSO_DOMAIN } = process.env;

export function Register() {
  const { tpage, tcommon } = useTranslation({
    page: "register",
  });

  const [fields, setFields] = useState({
    email: "",
    password: "",
    confirmedPassword: "",
    code: "",
    phoneNumber: "",
  });

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(tpage("error"));
  const [emailConfirmError, setEmailConfirmError] = useState(false);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(false);

  const [renderPasswordCriteria, validPassword, setPasswordForCriteria] =
    usePasswordCriteria();

  const phoneNumberIsValid = isPossiblePhoneNumber(fields.phoneNumber ?? "");

  const [disableSignUpBtn, setDisableSignUpBtn] = useState(false);

  const passwordConfirm = useRef(null);

  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search],
  );

  const unverified = searchParams.get("unverified");

  useEffect(() => {
    if (unverified) {
      setFields({
        email: searchParams.get("email"),
      });
      setNeedsConfirmation(true);
    }
  }, [searchParams]);

  const register = async (event) => {
    event.preventDefault();

    if (validPassword == false) return;

    if (phoneNumberIsValid == false) return;

    setDisableSignUpBtn(true);
    setLoading(true);

    try {
      const response = await fetch(`${REACT_APP_SSO_DOMAIN}/register`, {
        method: "POST",
        body: JSON.stringify(fields),
      });

      if (response.ok == false) {
        const text = await response.text();
        let errorMessage = "";

        if (text.includes("UsernameExistsException")) {
          errorMessage = tpage("account-exists-error");
        } else if (
          text.includes("missing phoneNumber") ||
          text.includes("invalid phone number format")
        ) {
          errorMessage = tpage("phone-number-error");
        } else {
          errorMessage = tpage("error");
        }

        setErrorMessage(errorMessage);
      }

      setError(response.ok == false);
      setNeedsConfirmation(response.ok);
    } catch {
      setError(true);
      setNeedsConfirmation(false);
    } finally {
      setLoading(false);
    }

    setDisableSignUpBtn(false);
  };

  const handleConfirmEmail = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const response = await fetch(`${REACT_APP_SSO_DOMAIN}/confirm`, {
        method: "POST",
        body: JSON.stringify(fields),
      });
      setEmailConfirmError(response.ok == false);
      setFinished(response.ok);
    } catch {
      setEmailConfirmError(true);
      setFinished(false);
    } finally {
      setLoading(false);
    }
  };

  const goToLogin = async (event) => {
    event.preventDefault();

    let params = window.localStorage.getItem("loginSearchParams") || "";

    /**
     * Tempory fix for ENG-858, remove after completion of ticket
     *  - Jamie M
     */
    if (!params) {
      params = "?redirect_uri=cloud.givenergy.installer://";
    }

    const urlParams = new URLSearchParams(params);
    urlParams.delete("email");
    urlParams.delete("unverified");
    location.href = `/login${params}`;
  };

  usePasswordCompare({ passwordConfirm }, fields);

  useEffect(() => {
    window.localStorage.setItem("loginSearchParams", window.location.search);
  }, []);

  return (
    <MainWrapper>
      <form onSubmit={register} className="space-y-3">
        {needsConfirmation && finished == false && (
          <div className="text-sm text-center">
            {tpage("confirmation")}: {fields.email}
          </div>
        )}

        {finished && <p className="text-center">{tpage("completed")}</p>}

        <Input
          className={clsx(needsConfirmation && "hidden")}
          label={tcommon("email")}
          id="email"
          type="email"
          name="email"
          value={fields.email}
          placeholder="new.user@givenergy.co.uk"
          onChange={(event) =>
            setFields({ ...fields, email: event.target.value })
          }
          required
        />

        <PhoneInput
          className={clsx(needsConfirmation && "hidden")}
          value={fields.phoneNumber}
          setValue={(value) => setFields({ ...fields, phoneNumber: value })}
        />

        <ExpandableBox
          className={clsx(needsConfirmation && "hidden")}
          title={tpage("why-are-we-asking")}
        >
          <p className="text-xs">{tpage("explanation")}</p>
        </ExpandableBox>

        {needsConfirmation && finished === false && (
          <>
            <Input
              label={tcommon("code")}
              id="code"
              name="code"
              value={fields.code}
              onChange={(event) =>
                setFields({ ...fields, code: event.target.value })
              }
              required
            />
            {emailConfirmError && (
              <p className="max-w-full text-sm text-danger-500">
                {tpage("invalid-code")}
              </p>
            )}
          </>
        )}

        {!needsConfirmation && finished === false && (
          <>
            <PasswordInput
              label={tcommon("password")}
              id="password"
              name="password"
              value={fields.password}
              placeholder="Password"
              onChange={(event) => {
                setFields({ ...fields, password: event.target.value });
                setPasswordForCriteria(event.target.value);
              }}
              required
            />

            <PasswordInput
              ref={passwordConfirm}
              label={tcommon("confirm")}
              id="password-confirm"
              name="confirmedPassword"
              value={fields.confirmedPassword}
              placeholder="Confirm Password"
              onChange={(event) =>
                setFields({ ...fields, confirmedPassword: event.target.value })
              }
              required
            />

            {renderPasswordCriteria()}

            {error && (
              <p className="max-w-full text-sm text-danger-500">
                {errorMessage}
              </p>
            )}
          </>
        )}

        {finished && (
          <Button onClick={goToLogin}>{tpage("go-to-login")}</Button>
        )}

        {finished === false && (
          <div className="flex justify-end">
            {needsConfirmation ? (
              <Button loading={loading} onClick={handleConfirmEmail}>
                {tcommon("confirm")}
              </Button>
            ) : (
              <Button
                disable={disableSignUpBtn ? "true" : "false"}
                type="submit"
                loading={loading}
              >
                {tpage("sign-up")}
              </Button>
            )}
          </div>
        )}
      </form>

      {!needsConfirmation && (
        <div className="flex justify-center gap-1 text-sm">
          <p>{tpage("login-cta")}</p>
          <a
            className="underline"
            href={`/login${
              window.localStorage.getItem("loginSearchParams") ?? ""
            }`}
          >
            {tpage("login")}
          </a>
        </div>
      )}
    </MainWrapper>
  );
}
