import { useMemo } from "react";

export function useStateSearchParam() {
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search],
  );

  const state = useMemo(() => {
    return searchParams
      .get("state")
      ?.split(",")
      .map((x) => x.split("|"))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
  }, [window.location.search]);

  return { state, searchParams };
}
