import { useTranslation as usei18n } from "react-i18next";

export function useTranslation({ ns, options, page, component }) {
  const { t, ...rest } = usei18n(ns, { ...options, keyPrefix: undefined });

  return {
    translate: t,
    tpage: (key, params) => t(`page.${page}.${key}`, params),
    tcommon: (key, params) => t(`word.${key}`, params),
    tcomponent: (key, params) => t(`component.${component}.${key}`, params),
    ...rest,
  };
}
