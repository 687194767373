import { useEffect, useRef, useState } from "react";
import { useTranslation } from "hooks/use-translation";
import { MainWrapper } from "components/main-wrapper";
import OtpInput from "components/otp-input";
import ErrorBanner from "components/error-banner";
import { useStateSearchParam } from "hooks/use-state-search-param";
import Button from "components/button";
import { ReactComponent as SendIcon } from "assets/send.svg";
import clsx from "clsx";

const { REACT_APP_SSO_DOMAIN } = process.env;

const OTP_CODE_LENGTH = 6;

export function Challenge() {
  const { tpage } = useTranslation({
    page: "challenge",
  });

  const { state, searchParams } = useStateSearchParam();

  const getError = () => {
    const urlError = searchParams.get("error");
    if (urlError == undefined) return "";
    return decodeURI(urlError);
  };

  const clearError = () => {
    searchParams.delete("error");
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${searchParams}`,
    );
    setError("");
  };

  const [fields, setFields] = useState({
    device: "",
    session: searchParams.get("session") ?? "",
    userId: searchParams.get("userId") ?? "",
    email: searchParams.get("email") ?? "",
    emailOtpCode: "",
  });

  const [error, setError] = useState(getError());
  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);

  const onSubmit = (event) => {
    event.preventDefault();

    if (emailOtpIsEmpty) {
      return;
    }

    setLoading(true);
    event.target?.submit();
  };

  useEffect(() => {
    if (state) {
      setFields({ ...fields, device: state["device"] });
    }
  }, []);

  const isSessionExpiredError = error === "not authorized";
  const isCodeMismatchError = error === "code mismatch";

  const emailOtpIsEmpty = fields.emailOtpCode.trim().length === 0;

  useEffect(() => {
    if (!error && fields.emailOtpCode.length === OTP_CODE_LENGTH) {
      // Ensure that loading state is shown when the form is submitted
      // programmatically.
      onSubmit({ preventDefault: () => {}, target: formRef.current });
    }
  }, [fields.emailOtpCode, formRef, error]);

  function createLoginHref() {
    return `/login${window.localStorage.getItem("loginSearchParams") ?? ""}`;
  }

  return (
    <MainWrapper>
      <div className="flex flex-col gap-6 justify-center">
        <h1 className="text-center font-bold text-lg">
          {tpage("two-factor-authentication")}
        </h1>
        <span className="text-center font-semibold">
          {fields.email
            ? tpage("enter-otp-custom", { email: fields.email })
            : tpage("enter-otp")}
        </span>

        <form
          ref={formRef}
          action={`${REACT_APP_SSO_DOMAIN}/challenge${window.location.search}`}
          method="POST"
          encType="multipart/form-data"
          className="flex justify-center gap-2"
          onSubmit={onSubmit}
        >
          <input type="hidden" name="device" value={fields.device} />
          <input type="hidden" name="session" value={fields.session} />
          <input type="hidden" name="userId" value={fields.userId} />
          <input type="hidden" name="email" value={fields.email} />
          <input
            type="hidden"
            name="emailOtpCode"
            value={fields.emailOtpCode}
          />

          <OtpInput
            inputLength={OTP_CODE_LENGTH}
            onCodeChange={(code) => {
              clearError();

              setFields((previousFields) => ({
                ...previousFields,
                emailOtpCode: code,
              }));
            }}
          />

          <Button
            icon
            type="submit"
            loading={loading}
            disabled={emailOtpIsEmpty}
          >
            <div
              className={clsx(
                "w-5 h-5 text-brand",
                emailOtpIsEmpty && "opacity-50",
              )}
            >
              <SendIcon />
            </div>
          </Button>
        </form>
        {error && (
          <ErrorBanner>
            {isSessionExpiredError && (
              <>
                <span>{tpage("session-expired-error")}</span>
                <br />
                <a className="underline" href={createLoginHref()}>
                  {tpage("return-to-login")}
                </a>
              </>
            )}
            {isCodeMismatchError && tpage("code-mismatch-error")}
          </ErrorBanner>
        )}

        <hr className="border-dashed" />

        <p>
          {tpage("if-not-receive-otp")}
          <a className="underline text-brand" href={createLoginHref()}>
            {tpage("log-in")}
          </a>{" "}
          {tpage("again")}
        </p>
        <p>
          {tpage("alternatively")}
          <a
            href="https://givenergy.co.uk/contact-us/"
            className="underline text-brand"
          >
            {tpage("contact-support-team")}
          </a>
        </p>
      </div>
    </MainWrapper>
  );
}
