import React from "react";
import logo from "assets/logo.svg";
import PropTypes from "prop-types";

export function MainWrapper({ children }) {
  return (
    <div className="bg-gradient-to-b from-white from-40% to-brand-200 h-full w-[100vw] flex items-center justify-center">
      <div className="p-5 space-y-5 overflow-auto bg-white shadow-xl max-w-screen-xs max-h-screen w-full">
        <img src={logo} width={150} className="mx-auto" />
        {children}
      </div>
    </div>
  );
}

MainWrapper.propTypes = {
  children: PropTypes.node,
};
