import { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import LoadingSpinner from "./loading-spinner";

const Button = forwardRef(function Button(
  { children, type = "button", className, loading, disabled, icon, ...rest },
  ref,
) {
  return (
    <button
      {...rest}
      ref={ref}
      type={type}
      disabled={disabled || loading}
      className={clsx(
        className ?? [
          "min-h-10",
          icon
            ? "w-10 h-10 flex justify-center items-center rounded-full"
            : "w-full px-3 py-2 transition-colors rounded-xl ring-1 ring-brand-300 shadow-md font-light bg-brand-200",
          !disabled && "hover:bg-brand-300",
          loading &&
            "flex justify-center items-center opacity-50 cursor-progress",
          loading && !icon && "bg-brand-300",
        ],
      )}
    >
      {loading ? <LoadingSpinner className="w-6 h-6" /> : children}
    </button>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(["button", "submit"]),
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
};

export default Button;
