import PropTypes from "prop-types";

function ErrorBanner({ children }) {
  return (
    <div className="bg-red-200 rounded-md p-2 text-center">
      <span className="text-red-600">{children}</span>
    </div>
  );
}

ErrorBanner.propTypes = {
  children: PropTypes.node,
};

export default ErrorBanner;
