import React from "react";
import PropTypes from "prop-types";
import GivEnergyLogo from "./givenergy-logo";

export function MainWrapper({ children }) {
  return (
    <div className="bg-gradient-to-b from-brand-100 from-40% to-primary-600 dark:to-brand-200 h-full w-[100vw] flex items-center justify-center">
      <div className="p-5 space-y-5 overflow-auto bg-brand-100 shadow-xl max-w-screen-xs max-h-screen w-full">
        <GivEnergyLogo className="mx-auto w-[150px] h-auto" />
        {children}
      </div>
    </div>
  );
}

MainWrapper.propTypes = {
  children: PropTypes.node,
};
