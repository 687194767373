import { ReactComponent as GivEnergyLogoSvg } from "assets/logo.svg";
import { ReactComponent as GivEnergyLogoDarkSvg } from "assets/logo-dark.svg";
import { useTheme } from "@givenergy/tailwind-theming";
import PropTypes from "prop-types";

function GivEnergyLogo({ className }) {
  const [theme] = useTheme();

  if (theme === "givenergy-dark") {
    return <GivEnergyLogoDarkSvg className={className} />;
  }

  return <GivEnergyLogoSvg className={className} />;
}

GivEnergyLogo.propTypes = {
  className: PropTypes.string,
};

export default GivEnergyLogo;
