import "./loading-spinner.css";
import PropTypes from "prop-types";
import clsx from "clsx";

function LoadingSpinner({ className }) {
  return <div className={clsx("loader", className)}></div>;
}

LoadingSpinner.propTypes = {
  className: PropTypes.string,
};

export default LoadingSpinner;
