import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "hooks/use-translation";
import Input from "components/input";
import Button from "components/button";
import PasswordInput from "components/password-input";
import { MainWrapper } from "components/main-wrapper";
import { useStateSearchParam } from "hooks/use-state-search-param";

const { REACT_APP_SSO_DOMAIN } = process.env;

export function Login() {
  const { translate, tcommon, tpage } = useTranslation({
    page: "login",
  });

  const { state, searchParams } = useStateSearchParam();

  const getError = () => {
    const urlError = searchParams.get("error");
    if (urlError == undefined) return "";
    return decodeURI(urlError);
  };

  const [fields, setFields] = useState({
    username: searchParams.get("email") ?? "",
    password: "",
    device: "",
  });

  const shouldDisableSignUp = searchParams.get("disable_sign_up") === "true";

  const [error] = useState(getError());
  const [loading, setLoading] = useState(false);

  const registerURL = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("error");
    return `/register?${params}`;
  };
  const forgottenPasswordURL = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("error");
    return `/forgot-password?${params}`;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    event.target.submit();
  };

  useEffect(() => {
    if (state) {
      setFields({ ...fields, device: state["device"] });
    }
    if (window.location.pathname === "/login") {
      window.localStorage.setItem("loginSearchParams", window.location.search);
    }
  }, []);

  return (
    <MainWrapper>
      <div className="w-full space-y-4">
        <p className="font-semibold text-xs text-center">
          {tpage("login-with-sso")}
        </p>

        <hr className="border-dashed" />

        <form
          action={`${REACT_APP_SSO_DOMAIN}/login${window.location.search}`}
          method="POST"
          encType="multipart/form-data"
          className="space-y-4"
          onSubmit={onSubmit}
        >
          <Input
            label={tpage("email-address")}
            id="email"
            name="email"
            type="email"
            placeholder="new.user@givenergy.co.uk"
            value={fields.username}
            onChange={(event) =>
              setFields({ ...fields, username: event.target.value })
            }
            required
          />

          <PasswordInput
            label={tcommon("password")}
            id="password"
            name="password"
            value={fields.password}
            onChange={(event) =>
              setFields({ ...fields, password: event.target.value })
            }
            required
          />

          <input type="hidden" name="device" value={fields.device} />
          {error && (
            <p className="max-w-full text-sm text-danger-500">
              <Trans
                t={translate}
                i18nKey={"page.login.invalid-credentials-cta"}
              />
            </p>
          )}

          <hr className="border-dashed" />

          <Button type="submit" loading={loading}>
            {tpage("login")}
          </Button>
        </form>

        <div className="flex flex-row items-center justify-between gap-4">
          <a
            className="underline text-xs text-primary"
            href={forgottenPasswordURL()}
          >
            {tpage("recover-cta")}
          </a>

          {!shouldDisableSignUp && (
            <a className="underline text-xs text-primary" href={registerURL()}>
              {tpage("register-cta")}
            </a>
          )}
        </div>
      </div>
    </MainWrapper>
  );
}
