import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";

function stringConsistsOfDigits(string) {
  return /^\d+$/.test(string);
}

function OtpInput({ onCodeChange, inputLength = 6 }) {
  const [code, setCode] = useState(Array(inputLength).fill(""));
  const inputRef = useRef(Array(inputLength).fill(null));

  const onInput = useCallback(
    (value, index) => {
      if (value.length > 1) {
        return;
      }

      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value !== "" && index < inputLength - 1) {
        inputRef.current[index + 1]?.focus();
      }

      if (value === "" && index > 0) {
        inputRef.current[index - 1]?.focus();
      }

      onCodeChange(newCode.join(""));
    },
    [inputRef, onCodeChange, code],
  );

  const onKeyDown = useCallback(
    (event, index) => {
      const backspacePressed = event.key === "Backspace";

      if (backspacePressed && code[index] === "" && index > 0) {
        event.preventDefault();
        inputRef.current[index - 1]?.focus();
      }
    },
    [inputRef, onInput],
  );

  const onPaste = useCallback(
    (event) => {
      event.preventDefault();

      const pastedText =
        (event.clipboardData || window.clipboardData)
          ?.getData("text")
          ?.trim() ?? "";

      if (
        !pastedText ||
        pastedText.length !== inputLength ||
        !stringConsistsOfDigits(pastedText)
      ) {
        return;
      }

      const newCode = pastedText.split("");
      setCode(newCode);

      onCodeChange(newCode.join(""));
    },
    [setCode, onCodeChange],
  );

  useEffect(() => {
    inputRef.current[0]?.focus();
  }, [inputRef]);

  return (
    <div className="flex justify-center gap-2">
      {Array.from({ length: inputLength }).map((_, i) => (
        <input
          ref={(ref) => (inputRef.current[i] = ref)}
          className="text-center px-2 rounded-md w-8 shadow-inner border-0 ring-1 ring-brand-300 focus:ring-brand bg-brand-100 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          key={i}
          type="number"
          min={0}
          max={9}
          step={1}
          maxLength={1}
          autoComplete="off"
          value={code[i]}
          onInput={(event) => onInput(event.target.value, i)}
          onKeyDown={(event) => onKeyDown(event, i)}
          onFocus={(event) => event.target.select()}
          onPaste={onPaste}
        />
      ))}
    </div>
  );
}

OtpInput.propTypes = {
  inputLength: PropTypes.number,
  onCodeChange: PropTypes.func,
};

export default OtpInput;
