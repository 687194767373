import clsx from "clsx";
import { useTranslation } from "hooks/use-translation";
import { useCallback, useEffect, useState } from "react";

export function usePasswordCriteria() {
  const { tcomponent } = useTranslation({ component: "password-criteria" });

  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState({
    hasCapitalLetter: false,
    hasNumber: false,
    hasSymbol: false,
    hasLength: false,
  });
  const [isValid, setIsValid] = useState(false);

  const validate = useCallback(
    (password) => {
      const result = {
        hasCapitalLetter: /[A-Z]/.test(password),
        hasNumber: /[0-9]/.test(password),
        hasSymbol: /[^a-z0-9]/i.test(password),
        hasLength: /^.{8,}$/.test(password),
      };

      setValidation(result);
      setIsValid(
        result.hasCapitalLetter &&
          result.hasLength &&
          result.hasNumber &&
          result.hasSymbol,
      );
    },
    [setValidation],
  );

  const validationClass = useCallback(
    (bool) =>
      bool ? "text-success-500" : "text-danger-500 dark:text-danger-400",
    [validation],
  );

  useEffect(() => {
    validate(password);
  }, [password]);

  const render = useCallback(() => {
    return (
      <div>
        <p className="text-sm text-primary-muted">
          <span>{tcomponent("must-have")}</span>{" "}
          <span className={clsx(validationClass(validation.hasCapitalLetter))}>
            {tcomponent("capital-letter")}
          </span>
          {tcomponent("a")}
          <span className={clsx(validationClass(validation.hasNumber))}>
            {tcomponent("number")}
          </span>
          {tcomponent("and-a")}
          <span className={clsx(validationClass(validation.hasSymbol))}>
            {tcomponent("symbol")}
          </span>
          {". "}
          <span>{tcomponent("must-also-be-at-least")}</span>{" "}
          <span className={clsx(validationClass(validation.hasLength))}>
            {tcomponent("eight-characters")}
          </span>
          {"."}
        </p>
      </div>
    );
  }, [validation]);

  return [render, isValid, setPassword];
}
